


















/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
    methods: {
        ...mapActions('auth', ['setAcceptRGPD'])
    },
    computed: {
        ...mapState('auth', ['error', 'acceptRGPD']),
        ...mapGetters('auth', ['is_intervenant_centre_nodirection'])
    }
})
export default class AcceptRGPD extends Vue {
    @Watch('acceptRGPD')
    redirect () {
        if (this.$store.state.auth.acceptRGPD) {
            if (this.$store.getters['auth/is_intervenant_centre_nodirection']) {
                this.$router.push('/dashboard_i').catch(() => { console.log('Erreur de redirection') })
            } else {
                this.$router.push('/dashboard').catch(() => { console.log('Erreur de redirection') })
            }
        }
    }

    errors = ''
    message = ''
    texte = `J'accepte que les données que j'ai renseignées via le site <a href="/">${process.env.VUE_APP_URL}</a> soient utilisées dans le cadre d'un possible recrutement par le concours, conformément aux dispositions énoncées dans la <a href="/politique-vie-privee" target="_blank">politique de vie privée</a>.`

    async sendAcceptation () {
        this.$store.dispatch('auth/setAcceptRGPD').then(() => {
            if (this.$store.state.auth.acceptRGPD) {
                if (this.$store.getters['auth/is_intervenant_centre_nodirection']) {
                    this.$router.push('/dashboard_i').catch(() => { console.log('Erreur de redirection') })
                } else {
                    this.$router.push('/dashboard').catch(() => { console.log('Erreur de redirection') })
                }
            }
        })
    }

    // Déconnexion
    logout () {
        this.$store.dispatch('auth/logout')
    }

    mounted() {
        const parameters = this.$store.getters['auth/authUser'].parameters
        if (parameters) {
            for (const parameter of parameters) {
                if (parameter.code === 'rgpdAcceptText' && parameter.value) {
                    this.texte = parameter.value
                }
            }
        }
    }
}
