


































import { Vue, Component } from 'vue-property-decorator'
import Documentations from './Documentations.vue'

@Component({
    components: {
        Documentations
    }
})
export default class Aide extends Vue {
    videos: Array<any> = []
    documents: Array<any> = []
    contenu: any = null
    video_select: any = null

    // Sélection d'une video
    selectVideo(video: any) {
        this.video_select = video
        for (const v in this.videos) {
            this.videos[v].select = false
        }
        this.video_select.select = true
    }

    // Récupération des données
    beforeMount() {
        this.contenu = this.$store.getters['auth/findParameter']('aides')
        if (this.contenu) {
            this.videos = this.contenu.videos || []
            this.documents = this.contenu.documents || []
            this.selectVideo(this.videos[0])
        }
    }
}
