













/* eslint-disable @typescript-eslint/no-unused-vars */

import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapState('user', ['notifications', 'maj_notification'])
    },
    methods: {
        ...mapActions('user', ['getNotifications'])
    }
})
export default class NotificationUser extends Vue {
    errors = null
    statuts_select: Array<any> = []

    @Watch('maj_notification')
    updateStatuts () {
        this.statuts_select = []
        for (const i in this.$store.state.user.notifications) {
            this.statuts_select.push(this.$store.state.user.notifications[i].status)
        }
    }

    // Update la notification
    majNotification (notification: any) {
        this.errors = null
        let status: number
        this.$store.state.user.maj_notification = false
        switch (!notification.status) {
            case true:
                status = 1
                break
            case false:
                status = 0
                break
        }

        const datas = {
            status: status
        }

        this.$store.dispatch('user/updateNotification', { notification_id: notification.id, payload: datas })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .catch((error) => {
                this.errors = error.response.data.errors
            })
    }

    mounted () {
        this.$store.dispatch('user/getNotifications').then(() => {
            for (const i in this.$store.state.user.notifications) {
                this.statuts_select.push(this.$store.state.user.notifications[i].status)
            }
        })
    }
}
