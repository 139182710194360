


























import { Ability } from '@/types/Ability'
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import InfoUser from '@/views/Auth/InfoUser.vue'
import OtpParam from '@/views/Auth/OtpParam.vue'
import NotificationUser from '@/views/Auth/NotificationUser.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin', 'can', 'cannot']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        InfoUser,
        OtpParam,
        NotificationUser
    }
})

export default class ProfilUser extends Vue {
    RH_SPS_DOS_OWN_MANAGE = Ability.RH_SPS_DOS_OWN_MANAGE
    RH_ADM_DOS_OWN_MANAGE = Ability.RH_ADM_DOS_OWN_MANAGE
    twoFactorsIsDisabled  = true
}
