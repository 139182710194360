














































import { Ability } from '@/types/Ability'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('auth', ['error', 'loading']),
        ...mapGetters('auth', ['authUser', 'can'])
    }
})
export default class OtpParam extends Vue {
    RH_SPS_DOS_OWN_MANAGE = Ability.RH_SPS_DOS_OWN_MANAGE
    RH_ADM_DOS_OWN_MANAGE = Ability.RH_ADM_DOS_OWN_MANAGE

    @Watch('error')
    getErrors () {
        this.errors = this.$store.state.auth.error
    }

    activeOtpHandler () {
        this.$store.dispatch('auth/setTwoFactorAuthentication').then(async () => {
            await this.$store.dispatch('auth/getAuthUser')
            this.chargerInfoOTP()
        })
    }

    disableOtpHandler () {
        this.$store.dispatch('auth/removeTwoFactorAuthentication').then(async () => {
            await this.$store.dispatch('auth/getAuthUser')
            this.otpQRCode = ''
            this.otpRecoveryCode = ''
        })
    }

    chargerInfoOTP () {
        this.$store.dispatch('auth/getOtpQrCode').then(async () => {
            this.otpQRCode = await this.$store.state.auth.twoFactorQrcode
        })
        this.$store.dispatch('auth/getTwoFactorRecoveryCodes').then(async () => {
            this.otpRecoveryCode = await this.$store.state.auth.twoFactorRecoveryCode
        })
    }

    async mounted () {
        // await this.$store.dispatch('auth/getAuthUser')
        if (this.$store.state.auth.user.twoFactor) {
            this.chargerInfoOTP()
        }
    }

    message = '';
    errors = '';
    otpQRCode = '';
    otpRecoveryCode = '';
    code_otp = '';
    update = 0;
}
