










































































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PhoneInput from '@/components/Tools/PhoneInput.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser'])
    },
    components: {
        PhoneInput
    }
})
export default class InfoUser extends Vue {
    errors = null
    message = null
    user = {
        civility: '',
        name: '',
        first_name: '',
        telephone: '',
        email: ''
    }

    phoneNumber = ''
    was_validated = false

    /** Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput */
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.user.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                    this.phoneNumber = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.user.telephone = ''
                }
            }
        }
    }

    submitUser () {
        this.errors = null
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.updatePhoneNumberInputData()
        this.was_validated = true

        this.$bvToast.toast('Modification en cours ...', infosToaster)
        const payload = {
            civility: this.user.civility,
            name: this.user.name,
            first_name: this.user.first_name,
            email: this.user.email,
            telephone: this.user.telephone
        }
        this.$store.dispatch('user/updateSelfUser', payload)
            .then((response) => {
                this.$store.dispatch('auth/getAuthUser')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.user.civility = response.data.data.civility
                this.user.name = response.data.data.name
                this.user.first_name = response.data.data.first_name
                this.user.telephone = response.data.data.telephone
                this.user.email = response.data.data.email
            })
            .catch((error) => {
                this.errors = error.response.data.errors
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    beforeMount () {
        this.user.civility = this.$store.state.auth.user.civility
        this.user.name = this.$store.state.auth.user.name
        this.user.first_name = this.$store.state.auth.user.first_name
        this.phoneNumber = this.$store.state.auth.user.telephone
        this.user.email = this.$store.state.auth.user.email
    }
}
