











































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import VuePdfApp from 'vue-pdf-app'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { base64ToArrayBuffer } from '@/utils/helpers'

@Component({
    computed: {
        Ability() {
            return Ability
        },
        ...mapGetters('auth', ['can']),
        ...mapGetters('documentation', ['error']),
        documentations() {
            return this.$store.getters['documentation/documentations']
                .filter((documentation: any) => documentation.actif)
        }
    },
    components: {
        VuePdfApp,
        ErrorDisplay
    }
})
export default class Aide extends Vue {
    open_loading = false
    open_pdf: any = null
    modal_loading = false
    delete_pdf_modal = false
    delete_pdf: any = null
    add_pdf_modal = false
    add_pdf: any = {
        name: '',
        code: '',
        ordre: 1,
        document: null,
        actif: true
    }

    pdf_config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    /**
     * onPdfModalChange
     * @description Réinitialise les erreurs et le chargement des modales
     * @return {void}
     */
    @Watch('add_pdf_modal')
    @Watch('delete_pdf_modal')
    onPdfModalChange(): void {
        this.$store.commit('documentation/SET_ERROR', null)
        this.modal_loading = false
    }

    /**
     * openPdf
     * @description Ouvre un PDF
     * @param {any} documentation - Documentation à ouvrir
     * @return {void}
     */
    openPdf(documentation: any): void {
        if (this.open_pdf?.id === documentation.id) { return }
        this.open_loading = true

        this.$store.dispatch('documentation/getPdfDocumentation', {
            documentation_id: documentation.id,
            document_id: documentation.document[0].id
        })
            .then(async (response: any) => this.open_pdf = {
                ...documentation,
                data: base64ToArrayBuffer(response.data)
            })
            .finally(() => this.open_loading = false)
    }

    /**
     * toggleDeletePdf
     * @description Ouvre ou ferme la modale de suppression de documentation
     * @param {boolean} state - Etat de la modale
     * @param {any} documentation - Documentation à supprimer
     * @return {void}
     */
    toggleDeletePdf(state: boolean, documentation: any = null): void {
        this.delete_pdf = documentation
        this.delete_pdf_modal = state
    }

    /**
     * deletePdf
     * @description Supprime un PDF
     * @return {void}
     */
    deletePdf(): void {
        if (!this.delete_pdf) { return }

        this.modal_loading = true
        this.$store.commit('documentation/SET_ERROR', null)

        this.$store.dispatch('documentation/deleteDocumentation', this.delete_pdf.id)
            .then(async () => {
                await this.$store.dispatch('documentation/getDocumentations', { perPage: -1, sort: 'ordre', direction: 'asc' })
                this.toggleDeletePdf(false)
            })
            .finally(() => this.modal_loading = false)
    }

    /**
     * toggleAddPdf
     * @description Ouvre ou ferme la modale d'ajout de documentation
     * @param {boolean} state - Etat de la modale
     * @return {void}
     */
    toggleAddPdf(state: boolean): void {
        this.add_pdf = {
            name: '',
            code: '',
            ordre: 1,
            document: null,
            actif: true
        }
        this.add_pdf_modal = state
    }

    /**
     * handleFileChange
     * @description Gère le changement de fichier
     * @param {any} event - Evènement de changement de fichier
     * @return {void}
     */
    handleFileChange(event: any): void {
        if (!event?.target) { return }
        this.add_pdf.document = event.target.files[0]
    }

    /**
     * addPdf
     * @description Ajoute un PDF
     * @return {void}
     */
    addPdf(): void {
        if (!this.add_pdf.name || !this.add_pdf.document) { return }
        const formdata = new FormData()

        formdata.append('name', this.add_pdf.name)
        formdata.append('code', this.add_pdf.code)
        formdata.append('ordre', this.add_pdf.ordre)
        formdata.append('document', this.add_pdf.document)
        formdata.append('actif', this.add_pdf.actif ? '1' : '0')
        formdata.append('_method', 'POST')

        this.modal_loading = true
        this.$store.commit('documentation/SET_ERROR', null)

        this.$store.dispatch('documentation/addDocumentation', formdata)
            .then(async () => {
                await this.$store.dispatch('documentation/getDocumentations', { perPage: -1, sort: 'ordre', direction: 'asc' })
                this.toggleAddPdf(false)
            })
            .finally(() => this.modal_loading = false)
    }

    /**
     * mounted
     * @description Récupère les documentations
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        await this.$store.dispatch('documentation/getDocumentations', { perPage: -1, sort: 'ordre', direction: 'asc' })
    }
}
