






































/* eslint-disable @typescript-eslint/no-unused-vars */

import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
    methods: {
        ...mapActions('auth', ['changePassword'])
    },
    computed: {
        ...mapState('auth', ['error', 'changepassword']),
        ...mapGetters('auth', ['isPasswordChanged', 'authUser'])
    }
})
export default class ResetPassword extends Vue {
    @Watch('error')
    getErrors () {
        this.errors = this.$store.state.auth.error
    }

    current_password = '';
    password = '';
    passwordConfirm = '';
    message = '';
    errors = ''

    updatePassword () {
        this.message = ''
        this.errors = ''
        const payload = {
            current_password: this.current_password,
            password: this.password,
            password_confirmation: this.passwordConfirm
        }

        this.$store.dispatch('auth/changePassword', payload).then(() => {
            this.$store.dispatch('auth/logout')
        })
    }
}
